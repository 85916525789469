// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-download-e-guide-js": () => import("./../../../src/pages/download-e-guide.js" /* webpackChunkName: "component---src-pages-download-e-guide-js" */),
  "component---src-pages-heavy-js": () => import("./../../../src/pages/heavy.js" /* webpackChunkName: "component---src-pages-heavy-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-light-js": () => import("./../../../src/pages/light.js" /* webpackChunkName: "component---src-pages-light-js" */),
  "component---src-pages-locations-fort-worth-js": () => import("./../../../src/pages/locations/fort-worth.js" /* webpackChunkName: "component---src-pages-locations-fort-worth-js" */),
  "component---src-pages-locations-kansas-js": () => import("./../../../src/pages/locations/kansas.js" /* webpackChunkName: "component---src-pages-locations-kansas-js" */),
  "component---src-pages-locations-mississippi-js": () => import("./../../../src/pages/locations/mississippi.js" /* webpackChunkName: "component---src-pages-locations-mississippi-js" */),
  "component---src-pages-locations-tennessee-js": () => import("./../../../src/pages/locations/tennessee.js" /* webpackChunkName: "component---src-pages-locations-tennessee-js" */),
  "component---src-pages-medium-js": () => import("./../../../src/pages/medium.js" /* webpackChunkName: "component---src-pages-medium-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-project-management-quality-control-js": () => import("./../../../src/pages/project-management-quality-control.js" /* webpackChunkName: "component---src-pages-project-management-quality-control-js" */),
  "component---src-pages-resources-blog-js": () => import("./../../../src/pages/resources/Blog.js" /* webpackChunkName: "component---src-pages-resources-blog-js" */),
  "component---src-pages-resources-blog-strapi-blog-post-slug-js": () => import("./../../../src/pages/resources/blog/{StrapiBlogPost.slug}.js" /* webpackChunkName: "component---src-pages-resources-blog-strapi-blog-post-slug-js" */),
  "component---src-pages-resources-case-studies-js": () => import("./../../../src/pages/resources/case-studies.js" /* webpackChunkName: "component---src-pages-resources-case-studies-js" */),
  "component---src-pages-resources-e-guides-js": () => import("./../../../src/pages/resources/e-guides.js" /* webpackChunkName: "component---src-pages-resources-e-guides-js" */),
  "component---src-pages-resources-forms-js": () => import("./../../../src/pages/resources/Forms.js" /* webpackChunkName: "component---src-pages-resources-forms-js" */),
  "component---src-pages-resources-js": () => import("./../../../src/pages/resources.js" /* webpackChunkName: "component---src-pages-resources-js" */),
  "component---src-pages-resources-news-js": () => import("./../../../src/pages/resources/News.js" /* webpackChunkName: "component---src-pages-resources-news-js" */),
  "component---src-pages-resources-news-strapi-news-post-slug-js": () => import("./../../../src/pages/resources/news/{StrapiNewsPost.slug}.js" /* webpackChunkName: "component---src-pages-resources-news-strapi-news-post-slug-js" */),
  "component---src-pages-schedule-tour-js": () => import("./../../../src/pages/schedule-tour.js" /* webpackChunkName: "component---src-pages-schedule-tour-js" */),
  "component---src-pages-services-assembly-js": () => import("./../../../src/pages/services/Assembly.js" /* webpackChunkName: "component---src-pages-services-assembly-js" */),
  "component---src-pages-services-coating-js": () => import("./../../../src/pages/services/Coating.js" /* webpackChunkName: "component---src-pages-services-coating-js" */),
  "component---src-pages-services-engineering-js": () => import("./../../../src/pages/services/Engineering.js" /* webpackChunkName: "component---src-pages-services-engineering-js" */),
  "component---src-pages-services-forming-js": () => import("./../../../src/pages/services/Forming.js" /* webpackChunkName: "component---src-pages-services-forming-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-services-laser-cutting-js": () => import("./../../../src/pages/services/laser-cutting.js" /* webpackChunkName: "component---src-pages-services-laser-cutting-js" */),
  "component---src-pages-services-machining-js": () => import("./../../../src/pages/services/Machining.js" /* webpackChunkName: "component---src-pages-services-machining-js" */),
  "component---src-pages-services-plasma-cutting-js": () => import("./../../../src/pages/services/plasma-cutting.js" /* webpackChunkName: "component---src-pages-services-plasma-cutting-js" */),
  "component---src-pages-services-welding-js": () => import("./../../../src/pages/services/Welding.js" /* webpackChunkName: "component---src-pages-services-welding-js" */)
}

